import React from "react"
import { IntercomProvider as BaseIntercomProvider, IntercomProviderProps } from "react-use-intercom"
import { getFullName } from "@Utils/common"
import { useGlobalContext } from "./GlobalContext"
import useIsFubEmbeddedApp from "@Hooks/fub/useIsFubEmbeddedApp"

export const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID || ""

type Props = React.PropsWithChildren<
  Omit<IntercomProviderProps, "appId" | "autoBoot"> &
    Partial<Pick<IntercomProviderProps, "appId" | "autoBoot">>
>

export const IntercomProvider = ({
  autoBoot = true,
  autoBootProps: _autoBootProps,
  children,
  ...props
}: Props) => {
  const { user } = useGlobalContext()
  const isFubEmbeddedApp = useIsFubEmbeddedApp()

  const autoBootProps = _autoBootProps
    ? _autoBootProps
    : user
    ? {
        email: user.email,
        name: `${getFullName({ firstName: user.firstName, lastName: user.lastName })}`,
      }
    : undefined

  if (!INTERCOM_APP_ID || isFubEmbeddedApp) {
    return <>{children}</>
  }

  return (
    <BaseIntercomProvider
      autoBoot={autoBoot}
      autoBootProps={autoBootProps}
      appId={INTERCOM_APP_ID}
      {...props}
    >
      {children}
    </BaseIntercomProvider>
  )
}
