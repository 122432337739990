import { useQuery } from "@blitzjs/rpc"
import { useMemo, createContext, useContext } from "react"
import { BooleanStrings, CustomerExtended, GroupExtended, UserExtended } from "@Types/common"
import { SocketEventTypes } from "app/sockets/types"
import getCurrentCustomer from "app/customer/queries/get-current-customer"
import getCurrentGroup from "app/management/groups/queries/get-current-group"
import getGlobalSettings from "app/management/settings/queries/get-global-settings"
import getCurrentUser from "app/users/queries/get-current-user"
import { useSocketEvent } from "app/sockets/useSocketEvent"

export interface GlobalContextValue {
  user: UserExtended | null
  customer: CustomerExtended | null
  group: GroupExtended | null
  settings: Record<string, any>
  refetchUser: () => Promise<void>
  refetchCustomer: () => Promise<void>
  refetchGroup: () => Promise<void>
}

export const GlobalContext = createContext<GlobalContextValue>({
  user: null,
  customer: null,
  group: null,
  settings: {},
  refetchUser: () => Promise.resolve(),
  refetchCustomer: () => Promise.resolve(),
  refetchGroup: () => Promise.resolve(),
})

function useGlobalContextProvider() {
  const [customer, { refetch: refetchCustomer }] = useQuery(getCurrentCustomer, {})
  const [user, { refetch: refetchUser }] = useQuery(getCurrentUser, {})
  const [group, { refetch: refetchGroup }] = useQuery(getCurrentGroup, {})
  const [settings] = useQuery(getGlobalSettings, {})

  useSocketEvent(SocketEventTypes.RefetchCustomer, refetchCustomer)

  const settingValuesPerSettingName = useMemo(
    () =>
      settings.reduce((acc, setting) => {
        const settingValue =
          setting.value === BooleanStrings.True
            ? true
            : setting.value === BooleanStrings.False
            ? false
            : setting.value
        acc[setting.settingName] = settingValue

        return acc
      }, {} as Record<string, any>),
    [settings]
  )

  const contextValue = useMemo((): GlobalContextValue => {
    return {
      user: user as UserExtended,
      customer: customer as CustomerExtended,
      group: group as GroupExtended,
      settings: settingValuesPerSettingName,
      refetchUser: async () => {
        await refetchUser()
      },
      refetchCustomer: async () => {
        await refetchCustomer()
      },
      refetchGroup: async () => {
        await refetchGroup()
      },
    }
  }, [
    user,
    customer,
    group,
    settingValuesPerSettingName,
    refetchUser,
    refetchCustomer,
    refetchGroup,
  ])

  return contextValue
}

export function useGlobalContext() {
  const context = useContext(GlobalContext)
  return context
}

type Props = React.PropsWithChildren<{}>

export const GlobalContextProvider: React.FC<Props> = ({ children }) => {
  const contextValue = useGlobalContextProvider()

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>
}
