import { enhancePrisma } from "blitz"
import { PrismaClient } from "@prisma/client"

const EnhancedPrisma = enhancePrisma(PrismaClient)

const enhancedPrisma = new EnhancedPrisma()

enhancedPrisma.$use(async (params, next) => {
  // Manipulate params here
  const result = await next(params)
  // See results here
  return result
})

export * from "@prisma/client"

export default enhancedPrisma
