import useMediaQuery from "./useMediaQuery"

export default function useBreakpoints() {
  const xxl = useMediaQuery("(min-width: 1600px)")
  const xl = useMediaQuery("(min-width: 1200px)")
  const lg = useMediaQuery("(min-width: 992px)")
  const md = useMediaQuery("(min-width: 768px)")
  const sm = useMediaQuery("(min-width: 576px)")
  const xs = useMediaQuery("(min-width: 480px)")
  const xxs = useMediaQuery("(max-width: 479px)")
  const isMeasured = xxl || xl || lg || md || sm || xs || xxs
  const isDesktop = xxl || xl || lg
  const isTablet = !isDesktop && md
  const isMobile = !isDesktop && (md || sm || xs || xxs)
  return { xxl, xl, lg, md, sm, xs, isMeasured, isMobile, isTablet, isDesktop }
}
