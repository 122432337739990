import {
  genderToCourtesyTitleMap,
  DEFAULT_MIN_IMAGE_DIMENSION,
  genderLabel,
  statesAndTerritoriesByAbbr,
  statesAndTerritoriesByName,
  VIDEO_STREAMING_ENABLED,
} from "@Constants/common"
import { CourtesyTitleValues } from "@Types/common"
import { CustomerFileType, File } from "db"
import { RouteUrlObject } from "blitz"
import { Reviewee } from "integrations/zillow/types"

export function getFullName({
  firstName = "",
  lastName = "",
  courtesyTitle = "",
  gender = "",
}: {
  firstName?: string | null
  lastName?: string | null
  courtesyTitle?: string | null
  gender?: string | null
}): string {
  if (!firstName && !lastName) {
    return ""
  }

  if (courtesyTitle === CourtesyTitleValues.FirstName) {
    return firstName || lastName || ""
  }

  if (courtesyTitle === CourtesyTitleValues.Formal && gender) {
    return [genderToCourtesyTitleMap[gender], firstName, lastName].filter(Boolean).join(" ")
  }

  return [firstName, lastName].filter(Boolean).join(" ")
}

export const getFullNameWithGreeting = (
  opportunityFullName: string,
  opportunityCourtesyTitle: string
) => {
  const greetingsPrefix = opportunityCourtesyTitle === "firstName" ? "Hi" : "Dear"

  return `${greetingsPrefix} ${opportunityFullName}`
}

export function getFullAddress({
  streetAddress = "",
  city = "",
  state = "",
  postalCode = "",
}): string {
  return `${streetAddress} ${city} ${state}${postalCode ? `, ${postalCode}` : ""}`.trim()
}

export function getShortAddress(streetAddress = ""): string {
  if (!streetAddress) return ""

  const regex = /^\d+\s+[a-zA-Z0-9\s]+/
  const match = streetAddress.match(regex)
  return match ? match[0].trim() : streetAddress
}

export function removeZipCodeFromFullAddress(streetAddress: string | undefined | null = "") {
  if (!streetAddress) return ""

  // This regex only works for USA zip codes format (##### or #####-####)
  const regex = /\b\d{5}(?:-\d{4})?\b(?=[^a-zA-Z0-9]*$)/
  return streetAddress.replace(regex, "").trim()
}

export function sanitizeUrl(url: string) {
  if (!/^https?:\/\//i.test(url)) {
    return "https://" + url
  }
  return url
}

export const unsanitizeUrl = (url?: string) => (url ? url.replace(/^https?:\/\//i, "") : url)

export function getStateFullName(stateAbbreviation: string | null | undefined): string {
  if (!stateAbbreviation) {
    return ""
  }
  if (statesAndTerritoriesByAbbr[stateAbbreviation]) {
    return statesAndTerritoriesByAbbr[stateAbbreviation]
  }
  return stateAbbreviation
}

export function getStateAbbreviation(name: string | null | undefined): string {
  if (!name) {
    return ""
  }
  if (statesAndTerritoriesByName[name]) {
    return statesAndTerritoriesByName[name]
  }
  return name.substring(0, 2)
}

export function countWords(text?: string | null) {
  if (typeof text !== "string") {
    return 0
  }
  let chunks = text.split(" ")
  chunks = chunks.filter((chunk) => chunk.trim().length)
  chunks = chunks.filter((chunk) => {
    const matches = chunk.match(/[\w\d\’\'-]+/gi)
    return matches && matches.length
  })
  return chunks.length
}

export function getGenderLabel(gender?: string | null) {
  if (!gender) return ""
  return genderLabel[gender] ?? ""
}

export function compareAvatars(photoUrl1: any, photoUrl2: any) {
  const _photoUrl1 = typeof photoUrl1 !== "string" ? "" : photoUrl1
  const _photoUrl2 = typeof photoUrl2 !== "string" ? "" : photoUrl2
  return (
    _photoUrl1 === _photoUrl2 || _photoUrl1.endsWith(_photoUrl2) || _photoUrl2.endsWith(_photoUrl1)
  )
}

export function groupArrayByTwoElements<T>(arr: T[]) {
  const groupedArray: T[][] = []
  for (let i = 0; i < arr.length; i += 2) {
    const subarray = arr.slice(i, i + 2)
    groupedArray.push(subarray)
  }
  return groupedArray
}

export function createIteration<T>(n: number, callbackfn?: (index: number) => T) {
  if (callbackfn === undefined) {
    return new Array(n).fill(null)
  }
  return new Array(n).fill(null).map((_, index) => callbackfn(index))
}

export function getRouteURL(route: RouteUrlObject) {
  const path = route.query
    ? Object.entries(route.query).reduce(
        (acc, [key, value]) => acc.replace(`[${key}]`, String(value)),
        route.pathname
      )
    : route.pathname

  return `${process.env.NEXT_PUBLIC_BASE_URL}${path}`
}

export function getCurrentUTCTimestampInSeconds() {
  return Math.floor(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      new Date().getUTCMinutes(),
      new Date().getUTCSeconds()
    ) / 1000
  )
}

export function getDateUTCTimestampInMiliseconds(date: Date) {
  if (!date) {
    return
  }

  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

export function getUrlParam(url: string, param: string) {
  try {
    const urlObject = new URL(url)
    return urlObject.searchParams.get(param)
  } catch (error) {
    return null
  }
}

export function roundToTwoDecimals(value: number) {
  return Math.round((value + Number.EPSILON) * 100) / 100
}

export function sortByValues<T, K extends keyof T>(data: T[], key: K, listOrder: T[K][] = []): T[] {
  const sortedData = [...data]

  sortedData.sort((a, b) => {
    const indexA = listOrder.indexOf(a[key])
    const indexB = listOrder.indexOf(b[key])
    if (indexA === -1) {
      return 1 // Place items with unknown values at the end
    }
    if (indexB === -1) {
      return -1 // Place items with unknown values at the end
    }
    return indexA - indexB
  })

  return sortedData
}

export function getGoogleReviewLink(placeId: string) {
  return `https://search.google.com/local/writereview?placeid=${placeId}`
}

export function getInlineZillowReviewee(reviewee: Reviewee) {
  return `${reviewee.RevieweeFullName}, ${reviewee.RevieweeEmail}, ${reviewee.RevieweeScreenName}`
}

export function hasWindowScroll() {
  return (
    (document.documentElement.scrollHeight || document.body.scrollHeight) >
    (document.documentElement.clientHeight ||
      (typeof window !== "undefined" ? window.innerHeight : 0))
  )
}

export function hasElementScroll(element: HTMLElement | null) {
  if (!element) {
    return false
  }
  return element.scrollHeight > element.clientHeight
}

export function isValidUrl(url: string) {
  const urlPattern = /^https?:\/\/.+/
  return urlPattern.test(url)
}

export async function checkImageDimensions(
  imageUrl: string | null | undefined,
  imageSmallestEdgeSize = DEFAULT_MIN_IMAGE_DIMENSION
) {
  if (!imageUrl) {
    return
  }

  try {
    const smallerEdge = await new Promise<number>((resolve, reject) => {
      const img = new Image()

      img.src = imageUrl

      img.onload = () => resolve(Math.min(img.width, img.height))
      img.onerror = () => reject(new Error("Image could not be loaded"))
    })

    if (smallerEdge < imageSmallestEdgeSize) {
      return `Image must be at least ${imageSmallestEdgeSize}px by ${imageSmallestEdgeSize}px`
    }
  } catch (error) {
    return error?.message || "Unexpected error"
  }
}

export async function ensureMinExecutionTime<T extends any>(
  asyncFunction: () => Promise<T>,
  minTime = 2000
): Promise<T> {
  const asyncTask = asyncFunction()

  // Wait for the async function to complete, but ensure it takes at least X seconds
  const result = await Promise.all([
    asyncTask,
    new Promise((resolve) => setTimeout(resolve, minTime)),
  ])

  return result[0]
}

export const getFileUrlsFromCustomerFiles = (
  customerFilesByType: Record<CustomerFileType, File[]>,
  fileType: CustomerFileType
) => (customerFilesByType[fileType] || []).map((file) => file.fileUrl)

export const returnStringAfterAndIncludingSubstring = (
  url: string,
  searchString: string
): string => {
  const startIndex = url.indexOf(searchString)
  if (startIndex !== -1) {
    return url.substring(startIndex)
  }

  return url
}

export const getStreamOrFileSrcForLocalVideo = (fileName: string) => {
  if (!VIDEO_STREAMING_ENABLED) {
    return `/videos/${fileName}`
  }

  return `/api/stream-video?file=${fileName}`
}

export function stringCompare(a: string | null | undefined, b: string | null | undefined): number {
  return (a || "").localeCompare(b || "")
}

export function dateCompare(a: Date | null | undefined, b: Date | null | undefined): number {
  return (b?.valueOf() ?? 0) - (a?.valueOf() ?? 0)
}

export function getDirtyValues<
  DirtyFields extends Record<string, unknown>,
  Values extends Record<keyof DirtyFields, unknown>
>(dirtyFields: DirtyFields, values: Values): Partial<typeof values> {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure why @react-hook-form sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) return prev

    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === "object"
          ? getDirtyValues(dirtyFields[key] as DirtyFields, values[key] as Values)
          : values[key],
    }
  }, {})

  return dirtyValues
}
